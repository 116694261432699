body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
h1,
h2,
h3,
span,
address {
  outline: none;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.challenge-iframe {
  width: 100%;
  height: 100vh;
}

.clover-footer {
  background: none !important;
}

.Toastify__toast--error {
  background-color: #ea170b;
}

.Toastify__toast {
  border-radius: 2px;
}
